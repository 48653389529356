import { injectIntl } from 'react-intl';

import { Theme } from '@emotion/react';
import { Box, Input, SxProps, styled } from '@mui/material';
import { IcoClose } from 'components/@icons';
import { ChangeEvent, ReactNode } from 'react';
import { theme } from 'theme';
import FlexWrapper from '../FlexWrapper/FlexWrapper';

const WrapperInputCustom = styled(Box)(({ theme }) => ({
  height: 40,
  color: theme.palette.neutral01[300],
  background: theme.palette.neutral01[600],
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  borderRadius: 20,
  overflow: 'hidden',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1.5),
  position: 'relative',
  ...theme.typography.webParagraph01,
}));

const InputCustom = styled(Input)(({ theme }) => ({
  height: 40,
  width: '100%',
  background: theme.palette.neutral01[600],
  outline: 'none',
  border: 'none',
  ':before': {
    display: 'none',
  },
  ':after': {
    display: 'none',
  },
  '.MuiInput-input': {
    '&::placeholder': {
      color: theme.palette.neutral01[300],
      opacity: 1,
    },
  },
  ...theme.typography.webParagraph01,
  padding: 0,
}));

interface Props {
  prefixIcon?: ReactNode;
  placeholderMessageId: any;
  sx?: SxProps<Theme>;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  intl: any;
  inputId?: string;
  allowClear?: boolean;
  onClear?: () => void;
}

const InputSearch = ({
  prefixIcon,
  placeholderMessageId,
  sx,
  onChange,
  value,
  intl,
  inputId,
  allowClear,
  onClear,
}: Props) => {
  const placeholder = intl.formatMessage({ id: placeholderMessageId });
  return (
    <WrapperInputCustom sx={{ ...sx }}>
      <FlexWrapper>{prefixIcon}</FlexWrapper>
      <InputCustom id={inputId} value={value} onChange={onChange} placeholder={placeholder} />
      {allowClear && value && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
            background: theme.palette.global01[600],
            ':hover': {
              background: theme.palette.global01[500],
            },
            padding: '2px',
            cursor: 'pointer',
          }}
          onClick={onClear}
        >
          <IcoClose fill={theme.palette.global01[400]} />
        </Box>
      )}
    </WrapperInputCustom>
  );
};

export default injectIntl(InputSearch);
