import { Box, Skeleton } from '@mui/material';

const LatestPressReleasesCardSkeleton = () => {
  return (
    <Box>
      <Skeleton variant="rectangular" width={'100%'} height={170} sx={{ borderRadius: '8px' }} />
      <Skeleton width="60%" />
      <Skeleton />
      <Skeleton />
    </Box>
  );
};

export default LatestPressReleasesCardSkeleton;
