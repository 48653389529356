import { Theme } from '@emotion/react';
import { Box, Button, SxProps, Typography } from '@mui/material';
import useMedia from 'hooks/useMedia';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';

interface Props {
  isFullWidth?: boolean;
  buttonText?: any;
  href?: string;
  variant?: 'text' | 'outlined' | 'contained';
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  sx?: SxProps<Theme>;
  sxText?: SxProps<Theme>;
  onClick?: () => void;
  spacingBetweenIcon?: number;
  type?: 'submit' | 'reset' | 'button' | undefined;
  disabled?: boolean;
}

const ButtonPrimary = ({
  isFullWidth,
  buttonText,
  variant = 'contained',
  prefixIcon,
  suffixIcon,
  sx,
  sxText,
  onClick,
  spacingBetweenIcon,
  type,
  disabled,
}: Props) => {
  const { isSmall } = useMedia();

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      fullWidth={isFullWidth || isSmall}
      size="medium"
      variant={variant}
      type={type}
      sx={{
        ...sx,
        fontSize: '13px !important',
      }}
    >
      {prefixIcon && (
        <Box sx={{ marginRight: theme.spacing(spacingBetweenIcon || 1), display: 'flex' }}>
          {prefixIcon}
        </Box>
      )}
      <Typography
        sx={{
          width: 'fit-content',
          display: 'flex',
          ...theme.typography.webParagraph03,
          ...sxText,
        }}
      >
        <FormattedMessage id={buttonText} />
      </Typography>
      {suffixIcon && (
        <Box sx={{ marginLeft: theme.spacing(spacingBetweenIcon || 1), display: 'flex' }}>
          {suffixIcon}
        </Box>
      )}
    </Button>
  );
};

export default ButtonPrimary;
