import { Theme } from '@emotion/react';
import { Box, Button, SxProps, Typography } from '@mui/material';
import useMedia from 'hooks/useMedia';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { theme } from 'theme';

interface Props {
  isFullWidth?: boolean;
  buttonText?: any;
  variant?: 'text' | 'outlined' | 'contained';
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  sx?: SxProps<Theme>;
  sxText?: SxProps<Theme>;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  spacingBetweenIcon?: number;
  disabled?: boolean;
}

const ButtonSecondary = ({
  isFullWidth,
  buttonText,
  variant = 'outlined',
  prefixIcon,
  suffixIcon,
  sx,
  sxText,
  onClick,
  spacingBetweenIcon,
  disabled,
}: Props) => {
  const { isSmall } = useMedia();
  return (
    <Button
      onClick={onClick}
      disabled={disabled || false}
      sx={{
        fontSize: '13px !important',
        border: '1px solid',
        ...sx,
      }}
      fullWidth={isFullWidth || isSmall}
      size="medium"
      variant={variant}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {prefixIcon && (
          <Box sx={{ marginRight: theme.spacing(spacingBetweenIcon || 1), display: 'flex' }}>
            {prefixIcon}
          </Box>
        )}

        <Typography
          sx={{
            width: 'fit-content',
            display: 'flex',
            ...theme.typography.webParagraph03,
            ...sxText,
          }}
        >
          <FormattedMessage id={buttonText} />
        </Typography>
      </Box>
      {suffixIcon && (
        <Box sx={{ marginLeft: theme.spacing(spacingBetweenIcon || 1), display: 'flex' }}>
          {suffixIcon}
        </Box>
      )}
    </Button>
  );
};

export default ButtonSecondary;
