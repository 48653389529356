import React, { ReactNode } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormattedMessage } from 'react-intl';
import { SxProps, Theme, Typography } from '@mui/material';
import { theme } from 'theme';

interface Props {
  buttonText?: any;
  loading?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  isFullWidth?: boolean;
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  loadingPosition?: 'center' | 'start' | 'end';
}

const LoadingButtonCustom = ({
  buttonText,
  loading,
  onClick,
  disabled = false,
  sx,
  prefixIcon,
  suffixIcon,
  isFullWidth,
  loadingPosition = 'start',
}: Props) => {
  return (
    <LoadingButton
      loading={loading}
      startIcon={prefixIcon}
      endIcon={suffixIcon}
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
      loadingPosition={loadingPosition}
      sx={{
        width: isFullWidth ? '100%' : 'fit-content',
        minWidth: 'fit-content',
        '&:hover': {
          background: disabled ? theme.palette.neutral01[700] : '',
        },
        ...sx,
      }}
    >
      <Typography
        sx={{
          width: 'fit-content',
          display: 'flex',
          ...theme.typography.webParagraph03,
        }}
      >
        <FormattedMessage id={buttonText} />
      </Typography>
    </LoadingButton>
  );
};

export default LoadingButtonCustom;
