import { Box, Typography, styled } from '@mui/material';

const ModalContent = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  maxWidth: 700,
  margin: 'auto',
  padding: theme.spacing(4),
  position: 'relative',
  top: theme.spacing(2),
  borderRadius: 4,
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ModalChildren = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const Header = styled(Box)(({ theme }) => ({}));

const ButtonClose = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 32,
  right: 32,
  cursor: 'pointer',
  display: 'flex',
  transition: '0.3s',
  borderRadius: '4px',
  ':hover': {
    background: theme.palette.global01[600],
  },
  [theme.breakpoints.down('sm')]: {
    top: 16,
    right: 16,
  },
}));

const TypographyTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.webHeading02,
  color: theme.palette.neutral01[100],
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const TypographyDescription = styled(Typography)(({ theme }) => ({
  ...theme.typography.webHeading06,
  color: theme.palette.neutral01[300],
  fontWeight: 400,
  marginTop: theme.spacing(1),
}));

export { ModalContent, ModalChildren, Header, ButtonClose, TypographyTitle, TypographyDescription };
