import { Theme } from '@emotion/react';
import { Modal, SxProps } from '@mui/material';
import { IcoCloseModal } from 'components/@icons';
import { ReactNode } from 'react';
import { theme } from 'theme';
import {
  ButtonClose,
  Header,
  ModalChildren,
  ModalContent,
  TypographyTitle,
  TypographyDescription,
} from './Modal.style';
import useMedia from 'hooks/useMedia';

interface Props {
  children?: ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  sx?: SxProps<Theme>;
  title?: ReactNode;
  description?: ReactNode;
  width?: number;
}

const ModalPrimary = (props: Props) => {
  const { isOpen, children, onClose, sx, title, description, width } = props;

  const { isSmall } = useMedia();

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onClose}
        closeAfterTransition={true}
        disableAutoFocus={true}
        sx={{
          background: 'rgba(0, 0, 0, 0.5)',
          overflowY: 'auto',
          paddingBottom: '100px',
          ...sx,
        }}
      >
        <ModalContent sx={{ maxWidth: width, margin: isSmall ? '12px' : 'auto' }}>
          <Header>
            <ButtonClose onClick={onClose}>
              <IcoCloseModal fill={theme.palette.neutral01[300]} />
            </ButtonClose>
            <TypographyTitle sx={{ marginRight: '25px' }}>{title}</TypographyTitle>
            <TypographyDescription>{description}</TypographyDescription>
          </Header>
          <ModalChildren>{children}</ModalChildren>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ModalPrimary;
