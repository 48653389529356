import { Box, styled } from '@mui/material';

export const LatestPressReleasesGrid = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$columns' && prop !== '$gap',
})<{ $columns: number; $gap: number }>(({ theme, $columns, $gap = 0 }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${$columns}, 1fr)`,

  gap: theme.spacing($gap),

  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
  },

  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1),
  },
}));

export const ButtonSelectCustom = styled(Box)(({ theme }) => ({
  color: theme.palette.global01[400],
  cursor: 'pointer',
  ...theme.typography.webParagraph02,
}));

export const Linear = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 1,
  display: 'flex',
  flex: 1,
  background: theme.palette.neutral01[600],
}));
