import { Box, Typography, styled } from '@mui/material';
import { IcoError } from 'components/@icons';
import FlexWrapper from '../FlexWrapper/FlexWrapper';

const BoxCustom = styled(Box)({
  display: 'flex',
  padding: '8px 16px',
  borderRadius: '8px',
  border: '1px solid',
  borderColor: '#ffccc7',
  backgroundColor: '#fff2f0',
});

interface Props {
  showAlert: boolean;
  message: string;
}

const AlertCustom = ({ showAlert, message }: Props) => {
  return (
    showAlert && (
      <BoxCustom sx={{ backgroundColor: '#fff2f0', width: 'fit-content', marginTop: '12px' }}>
        <FlexWrapper gap={1}>
          <IcoError width={24} height={24} />
          <Typography sx={{ fontSize: '12px', lineHeight: '24px' }}>{message}</Typography>
        </FlexWrapper>
      </BoxCustom>
    )
  );
};

export default AlertCustom;
