import { Theme } from '@emotion/react';
import { Box, Input, SxProps, styled } from '@mui/material';
import { LegacyRef, ReactNode } from 'react';
import FlexWrapper from '../FlexWrapper/FlexWrapper';
import { injectIntl } from 'react-intl';

const WrapperInputCustom = styled(Box)(({ theme }) => ({
  ...theme.typography.webParagraph01,
  height: 40,
  color: theme.palette.neutral01[400],
  borderRadius: 8,
  overflow: 'hidden',
  border: '1px solid',
  borderColor: theme.palette.neutral01[600],
}));

const InputCustom = styled(Input)(({ theme }) => ({
  height: '100%',
  width: '100%',
  outline: 'none',
  border: 'none',
  ':before': {
    display: 'none',
  },
  ':after': {
    display: 'none',
  },
  '.MuiInput-input': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: '100%',
    '&::placeholder': {
      color: theme.palette.neutral01[300],
      opacity: 1,
    },
  },
  ...theme.typography.webParagraph01,
}));

interface Props {
  prefixIcon?: ReactNode;
  placeholderMessageId: any;
  sx?: SxProps<Theme>;
  intl: any;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ref?: LegacyRef<HTMLDivElement> | any;
}

const InputSecondary = ({
  intl,
  prefixIcon,
  placeholderMessageId,
  sx,
  value,
  onChange,
  ref,
}: Props) => {
  const placeholder = intl.formatMessage({ id: placeholderMessageId });

  return (
    <WrapperInputCustom ref={ref} sx={{ ...sx }}>
      <FlexWrapper>{prefixIcon}</FlexWrapper>
      <InputCustom id="fullWidth" placeholder={placeholder} value={value} onChange={onChange} />
    </WrapperInputCustom>
  );
};

export default injectIntl(InputSecondary);
