import { MediaFileType, PressReleaseFileType } from './file-type.types';

export interface INewsroomCoverage {
  uuid?: string;
  title?: string;
  type?: TNewsroomCoverage;
  thumbUrl?: string;
  lead?: string;
  mediumType?: TMediumType;
  mediumTypeGroup?: TMediumTypeGroup;
  publishDate?: string;
  source?: string;
  sourceLogoUrl?: string;
  subSource?: string;
  belgapressUrl?: string;
}

export enum ENewsroomCoverage {
  NEWS = 'NEWS',
  CLIPPING = 'CLIPPING',
  ANALYSIS = 'ANALYSIS',
  DOCUMENT = 'DOCUMENT',
  BELGA_PHOTO = 'BELGA_PHOTO',
  BELGA_COVERAGE = 'BELGA_COVERAGE',
  BELGA_VIDEO = 'BELGA_VIDEO',
}

export enum EMediumType {
  ANALYSIS = 'ANALYSIS',
  NEWSPAPER = 'NEWSPAPER',
  WEBSITE = 'WEBSITE',
  BELGANEWS = 'BELGANEWS',
  WIRE = 'WIRE',
  MAGAZINE = 'MAGAZINE',
  RADIO = 'RADIO',
  TELEVISION = 'TELEVISION',
  BELGAALERT = 'BELGAALERT',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  VIDEO = 'VIDEO',
  PHOTOCOVERAGE = 'PHOTOCOVERAGE',
  AUDIO = 'AUDIO',
  PRESS_RELEASE = 'PRESS_RELEASE',
  AGENDA = 'AGENDA',
  ALERTS = 'ALERTS',
  COMING_UP = 'COMING_UP',
  TODAY_IN_HISTORY = 'TODAY_IN_HISTORY',
}

export enum EMediumTypeGroup {
  PRINT = 'PRINT',
  ONLINE = 'ONLINE',
  SOCIAL = 'SOCIAL',
  BELGA = 'BELGA',
  MULTIMEDIA = 'MULTIMEDIA',
  OTHER = 'OTHER',
}

export type TNewsroomCoverage =
  | ENewsroomCoverage.NEWS
  | ENewsroomCoverage.CLIPPING
  | ENewsroomCoverage.ANALYSIS
  | ENewsroomCoverage.DOCUMENT
  | ENewsroomCoverage.BELGA_PHOTO
  | ENewsroomCoverage.BELGA_COVERAGE
  | ENewsroomCoverage.BELGA_VIDEO;

export type TMediumType =
  | EMediumType.ANALYSIS
  | EMediumType.NEWSPAPER
  | EMediumType.WEBSITE
  | EMediumType.BELGANEWS
  | EMediumType.WIRE
  | EMediumType.MAGAZINE
  | EMediumType.RADIO
  | EMediumType.TELEVISION
  | EMediumType.BELGAALERT
  | EMediumType.INSTAGRAM
  | EMediumType.FACEBOOK
  | EMediumType.YOUTUBE
  | EMediumType.TWITTER
  | EMediumType.VIDEO
  | EMediumType.PHOTOCOVERAGE
  | EMediumType.AUDIO
  | EMediumType.PRESS_RELEASE
  | EMediumType.AGENDA
  | EMediumType.ALERTS
  | EMediumType.COMING_UP
  | EMediumType.TODAY_IN_HISTORY;

export type TMediumTypeGroup =
  | EMediumTypeGroup.PRINT
  | EMediumTypeGroup.ONLINE
  | EMediumTypeGroup.SOCIAL
  | EMediumTypeGroup.BELGA
  | EMediumTypeGroup.MULTIMEDIA
  | EMediumTypeGroup.OTHER;

export interface TNewsroom {
  id?: number;
  subSourceName?: string;
  logoUrl?: string;
  active?: boolean;
}

export interface IContent {
  en?: TLanguage;
  nl?: TLanguage;
  fr?: TLanguage;
}

export interface TLanguage {
  title?: string;
  lead?: string;
  body?: string;
  jobRole?: string;
}

export interface IPressReleases {
  id?: number;
  newsroom?: TNewsroom;
  content?: IContent;
  coverImageUrl?: string;
  publishDate?: string;
}

export interface IEmbed {
  name?: string;
  url: string;
}

export interface IAttachments {
  id?: number;
  type?: PressReleaseFileType.FILE | PressReleaseFileType.EMBED | PressReleaseFileType.FOLDER;
  file?: IFile;
  embed?: IEmbed;
  name?: string;
  language?: string;
}

export interface IAttachmentsEvent {
  fileName?: string;
  fileSize?: number;
  fileType?: MediaFileType;
  url?: string;
  id: string;
}

export type INewIAttachmentsEvent = Omit<IAttachmentsEvent, 'id'>;

export interface IPressReleasesDetail {
  id?: number;
  content?: IContent;
  topics?: ITopics[];
  coverImageUrl?: string;
  pressContacts?: TPressContacts[];
  attachments?: IAttachments[];
  publishDate?: string;
}

export interface INewsrooms {
  id?: number;
  subSourceName?: string;
  logoUrl?: string;
}

export interface ListResponse<T> {
  data: T[] | [];
  _meta?: {
    total: number;
  };
  _links?: {
    next: string | null | undefined;
    self: string | null | undefined;
  };
}

export interface SearchParams {
  offset: number;
  count: number;
  search?: string;
  language?: string;
  topicIds?: number[];
  start?: Date | null;
  end?: Date | null;
  newsroomId?: number | string;
  category?: string;
  parentId?: number | string | null;
  [key: string]: any;
}

export type TGeometry = {
  lat?: number;
  lng?: number;
};

export type TAddress = {
  street?: string;
  number?: string;
  zip?: string;
  city?: string;
  country?: string;
  geometry?: TGeometry;
};

export type TVenue = {
  name?: string;
  address?: TAddress;
};

export type TThumbnail = {
  id?: number;
  url?: string;
};

export type TProfilePicture = {
  id?: number;
  url?: string;
};

export interface ITopics {
  id?: number;
  content: IContent;
  thumbnailUrl?: string;
  category: string;
}

export type TPressContacts = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  content?: IContent;
  phoneNumber?: string;
  websiteUrl?: string;
  profilePictureUrl?: string;
  public?: boolean;
  language?: string;
};

export type TAttachments = {
  id?: number;
  url?: string;
  fileName?: string;
  fileSize?: number;
  fileType?: MediaFileType;
};

export type IFile = {
  url?: string;
  fileName?: string;
  fileSize?: number;
  fileType: MediaFileType;
};

export interface IContentEvent {
  en?: TLanguageEvent;
  nl?: TLanguageEvent;
  fr?: TLanguageEvent;
}

export interface TLanguageEvent {
  title?: string;
  description?: string;
  accreditation?: string;
}

export interface IEvents {
  id: number;
  content?: IContentEvent;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  allDay?: boolean;
  venue?: TVenue;
  topics?: ITopics;
  pressContacts?: TPressContacts;
  attachments?: TAttachments;
}

export interface IEvent {
  id?: number;
  content?: IContentEvent;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  allDay?: boolean;
  topics?: ITopics[];
  pressContacts?: TPressContacts[];
  attachments?: TAttachments[];
  venue?: string;
  street?: string;
  number?: string;
  zip?: string;
  city?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
}

export enum EDocumentAndMediaCategoryType {
  IMAGE = 'IMAGE',
  FOLDER = 'FOLDER',
  VIDEO = 'VIDEO',
  OTHER = 'OTHER',
}

export type TDocumentAndMediaCategoryType =
  | EDocumentAndMediaCategoryType.FOLDER
  | EDocumentAndMediaCategoryType.IMAGE
  | EDocumentAndMediaCategoryType.OTHER
  | EDocumentAndMediaCategoryType.VIDEO;

export enum EDocumentAndMediaType {
  FILE = 'FILE',
  FOLDER = 'FOLDER',
  EMBED = 'EMBED',
}

export type TDocumentAndMediaType =
  | EDocumentAndMediaType.FILE
  | EDocumentAndMediaType.FOLDER
  | EDocumentAndMediaType.EMBED;

export interface IDocumentAndMedia {
  id?: number;
  type?: TDocumentAndMediaCategoryType;
  parentId?: number | null;
  name?: string;
  thumbnail?: {
    mediaId?: number;
    url?: string;
  };
  embed?: {
    name?: string;
    url?: string;
  };
  meta?: {
    numberOfItems?: number;
  };
  file?: MediaFileType;
  updateDate?: string;
}

export enum EDays {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export type TDays =
  | EDays.MONDAY
  | EDays.TUESDAY
  | EDays.WEDNESDAY
  | EDays.THURSDAY
  | EDays.FRIDAY
  | EDays.SATURDAY
  | EDays.SUNDAY;

export enum ELanguage {
  NL = 'NL',
  FR = 'FR',
  EN = 'EN',
}

export type TLanguages = ELanguage.EN | ELanguage.NL | ELanguage.FR;

export interface IDataSubscribeToTopics {
  email: string;
  topicIds: number[];
  notifications?: {
    scheduling?: {
      instant?: boolean;
      intervals?: {
        days: string[];
        time: string;
      }[];
    };
    languages?: string[];
  };
}

export interface IStatusDownLoad {
  isDownLoad?: boolean;
  downloadProgress: number;
  fileName?: string;
  fileSize?: number | null;
  id: string;
}

export interface IDownLoading {
  id: string;
  downloadProgress: number;
  isDownload: boolean;
}

export enum EEventObject {
  NEWSROOM = 'NEWSROOM',
}

export enum EEventEnvironment {
  BELGA_SHARE_NEWSROOM = 'BELGA_SHARE_NEWSROOM',
  BELGA_SHARE_TAB_DOCUMENTS = 'BELGA_SHARE_TAB_DOCUMENTS',
  BELGA_SHARE_TAB_PRESS_RELEASES = 'BELGA_SHARE_TAB_PRESS_RELEASES',
  BELGA_SHARE_TAB_PRESS_COVERAGES = 'BELGA_SHARE_TAB_PRESS_COVERAGES',
  BELGA_SHARE_TAB_EVENTS = 'BELGA_SHARE_TAB_EVENTS',
}

export type TEventEnvironment =
  | EEventEnvironment.BELGA_SHARE_NEWSROOM
  | EEventEnvironment.BELGA_SHARE_TAB_PRESS_RELEASES
  | EEventEnvironment.BELGA_SHARE_TAB_DOCUMENTS
  | EEventEnvironment.BELGA_SHARE_TAB_EVENTS
  | EEventEnvironment.BELGA_SHARE_TAB_PRESS_COVERAGES;
