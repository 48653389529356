import { IDataSubscribeToTopics } from 'types/types';
import { NEWSROOMS_URL, TOPIC_URL } from './apiUrl';
import axiosClient from './axiosService';

export const subscribeForUpdateService = {
  subscribeToTopics: async (data: IDataSubscribeToTopics) => {
    return axiosClient()({
      url: `${TOPIC_URL}/subscribe`,
      method: 'POST',
      data: data,
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },
  followNewsrooms: async (
    newsroomsId: number | string,
    data: { email: string; languages: string[] },
  ) => {
    return axiosClient()({
      url: `${NEWSROOMS_URL}/${newsroomsId}/follow`,
      method: 'POST',
      data: data,
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },
};
