import QueryString from 'qs';
import {
  IDocumentAndMedia,
  IEvent,
  IEvents,
  INewsroomCoverage,
  INewsrooms,
  ListResponse,
  SearchParams,
} from 'types/types';
import { NEWSROOMS_URL } from './apiUrl';
import axiosClient from './axiosService';

export const newsRoomsService = {
  getNewsrooms: async (params?: SearchParams): Promise<ListResponse<INewsrooms>> => {
    return axiosClient()({
      url: NEWSROOMS_URL,
      method: 'GET',
      params,
      paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },

  getNewsRoomById: async (id: string | string[] | undefined): Promise<INewsrooms> => {
    return axiosClient()({
      url: `${NEWSROOMS_URL}/${id}`,
      method: 'GET',
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },

  getPressCoveragesByNewsRoomId: async (
    params: SearchParams,
  ): Promise<ListResponse<INewsroomCoverage>> => {
    return axiosClient()({
      url: `${NEWSROOMS_URL}/${params.id}/coverages`,
      method: 'GET',
      params,
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },

  getUpComingEventByNewsRoomId: async (params: SearchParams): Promise<ListResponse<IEvents>> => {
    return axiosClient()({
      url: `${NEWSROOMS_URL}/${params.id}/events`,
      method: 'GET',
      params,
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },

  getEventDetailByNewsRoomIdAndEventId: async (params: {
    eventId: number;
    newsroomsId: number;
  }): Promise<IEvent> => {
    return axiosClient()({
      url: `${NEWSROOMS_URL}/${params.newsroomsId}/events/${params.eventId}`,
      method: 'GET',
      params,
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },

  getDocumentsAndMediaByNewsRoomId: async (
    params: SearchParams,
    newsroomsId: string,
  ): Promise<ListResponse<IDocumentAndMedia>> => {
    return axiosClient()({
      url: `${NEWSROOMS_URL}/${newsroomsId}/documents`,
      method: 'GET',
      params,
      paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
    })
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  },
};
