export enum ENewsRoomsTab {
  'PRESS_RELEASE' = 'PRESS_RELEASE',
  'DOCUMENT' = 'DOCUMENT',
  'UPCOMING' = 'UPCOMING',
  'PRESS_COVERAGES' = 'PRESS_COVERAGES',
}

export enum EFileType {
  'DOCX' = 'DOCX',
  'XLSX' = 'XLSX',
  'PNG' = 'PNG',
  'JPG' = 'JPG',
  'PDF' = 'PDF',
}

export enum EDocumentType {
  'FILE' = 'FILE',
  'FOLDER' = 'FOLDER',
  'EMBED' = 'EMBED',
}

export enum ETabs {
  LATEST_PRESS_RELEASES = 'latest-press-releases',
  NEWSROOMS = 'newsrooms',
}
